import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams'
import { useState } from 'react'

import { isOptionsColumn } from './swr-data-grid-options-utils'

export function renderOptionsEditCell(params: GridRenderEditCellParams) {
  return <OptionsEditCell {...params} />
}

function OptionsEditCell({ id, field, colDef, api, value, hasFocus }: GridRenderEditCellParams) {
  const [selectValue, setSelectValue] = useState<string>(value)

  // The initial open state of the select is based on whether the cell has focus,
  // the result is that the options will directly open when the user starts editing the cell.
  const [open, setOpen] = useState(hasFocus)

  const handleChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value)
    api.setEditCellValue({ id, field, value: event.target.value || null })
  }

  if (!isOptionsColumn(colDef)) {
    return null
  }

  return (
    <FormControl
      sx={{ m: 0.5 }}
      fullWidth>
      <Select
        MenuProps={{
          slotProps: {
            paper: {
              // 36 = item height * 8.5 (8.5 items visible)
              // 8 = padding top
              style: { maxHeight: 36 * 8.5 + 8 },
            },
          },
        }}
        onChange={handleChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size={'small'}
        value={selectValue}
        autoWidth>
        {colDef.clearable !== false && (
          <MenuItem value={''}>
            <em>Clear</em>
          </MenuItem>
        )}
        {colDef.options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
