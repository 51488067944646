import { CreditListItem } from '@gain/rpc/cms-model'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { GridRowProps } from '@mui/x-data-grid/components/GridRow'
import { ReactElement, useRef } from 'react'

import InputForm from '../../common/input-fields'
import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import SwrRowActions from '../../common/swr-data-grid/swr-row-actions'
import CmsLink from '../../features/cms-link'
import LayoutDataGrid from '../../layout/data-grid'
import CreateCreditButton from './create-credit-button'

export default function RouteCredits() {
  const dataGridRef = useRef<SwrDataGridRef>(null)

  return (
    <LayoutDataGrid title={'Credits'}>
      <Card>
        <SwrDataGrid<'data.listCredits', CreditListItem>
          ref={dataGridRef}
          actions={[<CreateCreditButton key={'create-new-credit-action'} />]}
          columns={[
            {
              field: 'assetName',
              headerName: 'Target',
              flex: 1,
              minWidth: 150,
              filterable: false,
              renderCell: ({ row }) => (
                <CmsLink
                  key={row.asset?.id}
                  id={row.asset?.id}
                  label={row.asset?.name}
                  type={'asset'}
                />
              ),
            },
            {
              field: 'lenderNames',
              headerName: 'Lenders',
              flex: 1,
              minWidth: 150,
              filterable: false,
              renderCell: ({ row }) => (
                <Stack
                  display={'inline-flex'}
                  flexDirection={'row'}
                  gap={0.5}>
                  {row.lenders.map((lender) => (
                    <CmsLink
                      key={lender.id}
                      id={lender.id}
                      label={lender.name}
                      type={'lender'}
                    />
                  ))}
                </Stack>
              ),
            },
            {
              field: 'live',
              headerName: 'Status',
              minWidth: 150,
              type: 'boolean',
              renderCell: ({ value }) => (
                <Chip
                  color={value ? 'success' : 'info'}
                  label={value ? 'Published' : 'Draft'}
                  size={'small'}
                  variant={'outlined'}
                />
              ),
            },
            {
              field: 'publishedAt',
              headerName: 'Published at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'unpublishedAt',
              headerName: 'Unpublished at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'updatedAt',
              headerName: 'Updated at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'createdAt',
              headerName: 'Created at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'actions',
              type: 'actions',
              getActions: SwrRowActions,
            },
          ]}
          label={'Credits'}
          method={'data.listCredits'}
          path={':id'}
          renderRow={({ rowId, row }: GridRowProps, children: ReactElement) => (
            <InputForm
              deleteMethod={'data.deleteCredit'}
              isDisabled={() => row.live}
              name={'credit'}
              publishMethod={'data.publishCredit'}
              recordId={`${rowId}`}
              unPublishMethod={'data.unpublishCredit'}
              validateMethod={'data.validateCredit'}>
              {children}
            </InputForm>
          )}
          sort={[{ field: 'updatedAt', direction: 'desc' }]}
          useFullHeight
        />
      </Card>
    </LayoutDataGrid>
  )
}
